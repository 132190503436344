import { Modal } from '@patrianna/core-components'
import type { ModalComponent } from '@patrianna/shared-patrianna-types/store/DialogModule'
import classes from './styles.module.scss'

type Props = {
  open: boolean
  closeLatestDialog: () => void
  dialogComponents: ModalComponent[]
  disablePortal?: boolean
  toggleFullScreen?: Function
  lightBackdropMode: boolean
}

function RootDialog(props: Props) {
  return (
    <Modal
      BackdropProps={{
        style: { backgroundColor: props.lightBackdropMode ? 'rgba(0, 0, 0, 0.35)' : 'rgba(0, 0, 0, 0.75)' },
      }}
      disablePortal={props.disablePortal}
      onClose={props.closeLatestDialog}
      open={props.open}
    >
      <>
        {props.dialogComponents.map((modal, index) => {
          const Component = modal.component

          return (
            <div key={modal.id} style={{ zIndex: modal?.options?.zIndex || index }} className={classes.root}>
              <Component
                queryParams={modal.dialogProps}
                closeLatestDialog={props.closeLatestDialog}
                toggleFullScreen={props?.toggleFullScreen}
              />
            </div>
          )
        })}
      </>
    </Modal>
  )
}

export default RootDialog
